import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from 'environments/environment';
import { LicensesService } from '../new-student/licenses.service';
import { Observable, from, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

declare var ga;

export interface ClassData {
    // Instance for class in allClasses list
    idGuid: string;
    name: string;
    desctiption: string;
}

@Injectable()
export class User {
    data: {
        uid?: string;
        googleUid?: string;
        appleUid?: string;
        idGuid?: string;
        token?: any;
        username?: string;
        password?: string;
        repeatPassword?: string;
        email?: string;
        displayName?: string;
        firstName?: string;
        lastName?: string;
        photoURL?: string;
        managerId?: string;
        // All classes student has access to
        allClasses?: ClassData[];

        /**
         * Information about a proposed / current class for signup processing
         */
        classData?: {
            Id: string;
            classId: string;
            className: string,
            ClassroomId: number;
            CustomerId: number,
            OrganizationId: number,
            StudentPaid: boolean;
            instructorName: string;
            orgStudentPaid: boolean;
        };
    } = {};

    newUser: boolean;

    constructor(
        public http: HttpClient,
        public auth: AuthService,
        public licensesService: LicensesService
    ) {
        // Listen for Google Auth and overwrite my values to those from Firebase
        if (auth.user) {
            this.data.displayName = auth.user.displayName;
            this.data.email = auth.user.email;
            this.data.uid = auth.user.uid;
            this.data.photoURL = auth.user.photoURL;
        }

        // Why would idGuid come from google?!
        auth.userAuthData.subscribe(next => {
            console.log('Data updated from firebase or login', next);
            if (!next) {
                return;
            }

            this.data.token = next.TokenID;
            this.data.displayName = next.displayName;
            this.data.email = next.email;
            this.data.idGuid = next.idGuid;
        });

        this.getClassesForStudent(this.data.token);
    }

    /**
     * Send the data to the database.
     * This should work wehther we CREATE a student, or if one already exists.
     * Then save a reference in Firebase (if appropriate)
     */
    createStudent(service: string, token?: string): Observable<any> {
        let data: any;
        ga('send', 'event', 'New Student', 'created');
        if (service) {
            return from(this.auth.getCurrentFirebaseUser()).pipe(mergeMap(() => {
                this.data.displayName = this.auth.user.displayName;
                this.data.email = this.auth.user.email;
                this.data.uid = this.auth.user.uid;
                this.data.googleUid = this.auth.user.googleUid;
                this.data.appleUid = this.auth.user.appleUid;
                this.data.photoURL = this.auth.user.photoURL;
                this.data.token = token ? token : this.auth.syncToken;

                data = {
                    firstName: this.data.firstName,
                    lastName: this.data.lastName,
                    email: this.data.email,
                    photoURL: this.data.photoURL,
                    classId: this.data.classData.classId,
                    user: this.data,
                    username: this.data.username,
                    service: service,
                    userPaidForClass: true,
                    uid: this.data.uid,
                    googleUid: this.data.googleUid,
                    appleUid: this.data.appleUid,
                    token: this.data.token
                }

                return this.checkAccountValidity(data);
            }));
        } else {
            data = {
                firstName: this.data.firstName,
                lastName: this.data.lastName,
                email: this.data.email,
                photoURL: this.data.photoURL,
                classId: this.data.classData.classId,
                user: this.data,
                username: this.data.username,
                token: token ? token : this.auth.syncToken,
                userPaidForClass: true,
                password: this.data.password
            }

            return this.checkAccountValidity(data);
        }
    }

    private checkAccountValidity(data: any): Observable<any> {
        return this.licensesService.checkAccountValidity(this.data.classData.classId)
        .pipe(mergeMap(validityRes => {
            if (validityRes.success) {
                if (validityRes.res) {
                    return this.continueWithStudentCreation(data);
                } else {
                    return of({success: false, error: 'Please go to my account and renew licenses in order to create a student'});
                }
            } else {
                return of({success: false, error: `There was an error while trying to check your license validity. Please try again`});
            }
        }));
    }

    private continueWithStudentCreation(data): Observable<any> {
        if (!data.token) {
            console.log('Creating student on the server', data);

            // Send the data to the DB and get the token of the created user
            const req = this.http.post<any>(environment.NApiDomain + '/create-student', data);
            return req.pipe(map(createStudentData => {
                if (createStudentData && createStudentData.token && createStudentData.username) {
                    this.data.username = createStudentData.username;
                    this.data.token = createStudentData.token;
                    this.data.idGuid = createStudentData.idGuid;
                    this.auth.autoLogin(createStudentData.token);
                    this.newUser = true;
                    return {success: true, user: {displayName: data.displayName, token: this.data.token, email: data.email, allClasses: data.allClasses, username: this.data.username}};
                } else {
                    return {success: false, error: createStudentData.error ? createStudentData.error : 'Something went wrong with creating your account. Please try again later.'};
                }
            }, err => {
                console.log('Error:', err);
                return {success: false, error: 'We were unable to connect to the server to create your account. Please try again later.'};
            }));
        } else {
            console.log('Adding this class to the student\'s account.', data);
            const req = this.http.post<any>(`${environment.NApiDomain}/add-class`, data);
            return req.pipe(map(addingClassData => {
                if (addingClassData.success) {
                    console.log('Set idGuid to', addingClassData.idGuid);
                    this.data.idGuid = addingClassData.idGuid;
                    this.newUser = false;
                    return {success: true,  user: {displayName: data.displayName, token: data.token, email: data.email, allClasses: data.allClasses}};
                } else if (addingClassData.error) {
                    return {success: false, error: addingClassData.error};
                } else {
                    return {success: false, error: 'Unkown error while adding a class to your account.'};
                }
            }));
        }
    }

    getClassesForStudent(token) {
        if (token) {
            this.http.get<any>(`${environment.NApiDomain}/all-classes/${token}`)
                .subscribe(result => {
                    this.data.allClasses = [];
                    for (const classInstance of result.allClasses) {
                        const tempClass: ClassData = {
                            name: classInstance.Name,
                            desctiption: classInstance.Description,
                            idGuid: classInstance.IdGuid
                        };
                        this.data.allClasses.push(tempClass);
                    }
                });
        } else {
            console.log('Can\'t get classes for student - token is ', this.data.token);
        }
    }
}
